import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/Layout";
import Marquee from "react-fast-marquee";
import JobTag from "../components/JobTag";
import JobList from "../components/JobList";
import ListItem from "../components/ListItem";
import Head from "../components/Head";


export const query = graphql`
query($slug: String){
sanityJob(slug: {current: {eq: $slug}}) {
    title
  datum(formatString: "DD.MM.YY", locale: "de-DE")
  raum
  stunden
  introtext
  aufgabentext
  skills
  {
    title
    color
  }
  benefits
    konditionen
    bewerben
} 
 allSanitySettings {
    edges {
      node {
        instalink
        fblink
        linkedinlink
      }
    }
  } 
}
    `;

const Job = ({data}) => {
    return (
    <Layout settings={data.allSanitySettings.edges[0].node}>
        <Head title={data.sanityJob.title}/>

        <Marquee gradient={false} speed={40} className={'mobilemargin'}>
            <div className="marqueeText color-orange">
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
                <span className="marqueeText__inner">JOBS</span>
            </div>
        </Marquee>
        <div className="job__container">
            <div className="job__row">
                <div className="job__left">

                </div>
                <div className="job__right">
                    <h4 className={"uppercase"}>{data.sanityJob.title}</h4>
                </div>
            </div>
            <div className="job__row">
                <div className="job__left">
                    <p className="large">Datum: <br className="desktop"/> {data.sanityJob.datum}</p>
                </div>
                <div className="job__right">
                    <div className="flex-dual margin-top2">
                        <p className="large">Raum: <br/>{data.sanityJob.raum}</p>
                        <p className="large">{data.sanityJob.stunden} Std/ <br/>Woche</p>
                    </div>
                    <p className="large margin-top">{data.sanityJob.introtext}</p>
                </div>
            </div>
            <div className="job__row">
                <div className="job__left">
                    <p className="large mobile-small mobile-bold">Deine <br className="desktop"/>Aufgaben<span className="mobile">:</span></p>
                </div>
                <div className="job__right">
                    <p className="small">{data.sanityJob.aufgabentext}</p>
                </div>
            </div>
            <div className="job__row">
                <div className="job__left">
                    <p className="large mobile-small mobile-bold">Das bringst <br className="desktop"/>du mit<span className="mobile">:</span></p>
                </div>
                <div className="job__right">
                    <div className="jobtag__container">
                        {data.sanityJob.skills.map(node => (
                            <JobTag color={node.color}>{node.title}</JobTag>
                        ))
                        }
                    </div>
                </div>
            </div>
            <div className="job__row">
                <div className="job__left">
                    <p className="large mobile-small mobile-bold">Das bieten <br className="desktop"/>wir dir<span className="mobile">:</span></p>
                </div>
                <div className="job__right">
                    {data.sanityJob.benefits.map(node => (
                        <ListItem>{node}</ListItem>
                        ))
                    }
                </div>
            </div>
            <div className="job__row">
                <div className="job__left">
                    <p className="large mobile-small mobile-bold">Konditionen</p>
                </div>
                <div className="job__right">
                    {data.sanityJob.konditionen.map(node => (
                        <ListItem>{node}</ListItem>
                    ))
                    }
                </div>
            </div>
            <div className="job__row">
                <div className="job__left">
                    <p className="large"></p>
                </div>
                <div className="job__right">
                    <a href={"mailto:" + data.sanityJob.bewerben} className="btn outline black smalltext bold superlarge karriereLink">HIER BEWERBEN</a>
                </div>
            </div>
        </div>


    </Layout>
)};

export default Job;
